import { clearImage } from '../utils/image'
import { getLivenessGateway } from '../constants/gateways'

export const checkLiveness = async (photo: string) => {
    return fetch(`${getLivenessGateway()}/liveness`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            livenessParameters: [
                {
                    name: 'IDRND_CHECK_PROBABILITY',
                    value: '0.3'
                }
            ],
            image: clearImage(photo)
        })
    }).then((response) => response.json())
}
