import { FooterBlock, Line, NewStepWrapper, Notification } from './styles'
import { Button } from '../../../../components'

import { isMobile } from 'react-device-detect'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { selectModalBackground, setModalStatus } from '../../../../redux/slices/modalSlice'
import { FlowStatus } from '../../../../constants/flow'
import { Flex1 } from '../../../Modal/styles'

const NewStep = () => {
  const dispatch = useAppDispatch()
  const background = useAppSelector(selectModalBackground)

  const startHandler = () => {
    dispatch(
      setModalStatus({
        status: isMobile ? FlowStatus.NUMBER_FORM : FlowStatus.SWITCH_TO_MOBILE,
        showPrev: !isMobile,
        background: '#f2f2f7'
      })
    )
  }
  return (
    <NewStepWrapper>
      <img src='./img/flow-new-logo.svg' className='logo' alt='logo' />
      <h2>Let's get you verified in order to issue your Digital ID</h2>
      <p>Before you start, please:</p>

      <Line>
        <img src='./img/mark-black.svg' alt='mark' />
        <span>Make sure you have your national ID number as it will be required to initiate the process.</span>
      </Line>
      <Line>
        <img src='./img/mark-black.svg' alt='mark' />
        <span>Check if your device’s camera is uncovered and working</span>
      </Line>
      <Flex1 />
      <FooterBlock background={background}>
        <Notification>
          <span>
            Your session audio and video may be recorded.
            <br />
            By selecting “Start Verification” you agree to our
          </span>
          <a href='https://folio.id'>Terms & Conditions</a>
        </Notification>
        <Button key={new Date().getTime()} onClick={startHandler}>
          Get Started
        </Button>
      </FooterBlock>
    </NewStepWrapper>
  )
}

export default NewStep
