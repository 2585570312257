import {nets, utils, TinyFaceDetectorOptions} from 'face-api.js';

export const TINY_FACE_DETECTOR = 'tiny_face_detector'

const getCurrentFaceDetectionNet = () => {
    return nets.tinyFaceDetector
}

export const isFaceDetectionModelLoaded = () => {
    return !!getCurrentFaceDetectionNet().params
}

const inputSize = 512
const scoreThreshold = 0.5

export const getFaceDetectorOptions = () => {
    return new TinyFaceDetectorOptions({ inputSize, scoreThreshold })
}

let forwardTimes: number[] = []

export const updateTimeStats = (timeInMs: number) => {
    forwardTimes = [timeInMs].concat(forwardTimes).slice(0, 30)
    const avgTimeInMs = forwardTimes.reduce((total, t) => total + t) / forwardTimes.length
    console.log(`${Math.round(avgTimeInMs)} ms`)
    console.log(`${utils.round(1000 / avgTimeInMs)}`)
}

export const changeFaceDetector = async (detector: string) => {
    console.log('changeFaceDetector');
    if (!isFaceDetectionModelLoaded()) {
        console.log('true');
        await getCurrentFaceDetectionNet().load('/')
        console.log('true 2');
    }
}
