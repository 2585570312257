import styled from 'styled-components'

export const ErrorText = styled.div`
    align-self: flex-start;
    margin: 5px 32px;

    color: var(--Sys-Colors-Red, #ff3b30);
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.08px;
`
