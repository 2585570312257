import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface ConfigState {
    debug: boolean
}

// Define the initial state using that type
const initialState: ConfigState = {
    debug: false
}

export const configSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setDebug: (state, action: PayloadAction<{ debug: boolean }>) => {
            const { debug } = action.payload
            state.debug = debug
        },
        resetConfig: (state) => {
            state = initialState
        }
    }
})

// Action creators are generated for each case reducer function
export const { setDebug, resetConfig } = configSlice.actions

export const selectDebug = (state: RootState) => state.config.debug

export default configSlice.reducer
