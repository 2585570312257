import { getAlbaniaGateway } from '../constants/gateways'

type Preview = {
    frontside: string
    thumbnail: string
}
export const generatePreview = async (token: string): Promise<Preview> => {
    return fetch(`${getAlbaniaGateway()}/api/v1/albania/document/preview/${token}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => response.json())
}
