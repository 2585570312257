import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { FlowStatus } from '../../constants/flow'

interface ModalState {
    opened: boolean
    showHeader: boolean
    status?: FlowStatus
    subStatus?: string
    prevStatus?: FlowStatus
    background?: string
}

// Define the initial state using that type
const initialState: ModalState = {
    opened: false,
    showHeader: true
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<{ reset: boolean } | undefined>) => {
            const { reset } = action.payload ?? {}
            state.opened = true
            if (reset) {
                state.status = FlowStatus.NEW
            } else {
                state.status = state.status ?? FlowStatus.NEW
            }
        },
        closeModal: (state) => {
            state.opened = false
        },
        setModalStatus: (
            state,
            action: PayloadAction<{
                status: FlowStatus
                showPrev?: boolean
                background?: string
                showHeader?: boolean
                subStatus?: string
            }>
        ) => {
            const { status, showPrev, background, showHeader, subStatus } = action.payload
            state.prevStatus = showPrev ? state.status : undefined
            state.status = status
            state.background = background
            state.showHeader = showHeader ?? true
            state.subStatus = subStatus
        },
        resetModalStatus: (state) => {
            state.status = FlowStatus.NEW
            state.prevStatus = undefined
            state.background = undefined
        }
    }
})

// Action creators are generated for each case reducer function
export const { openModal, closeModal, setModalStatus, resetModalStatus } = modalSlice.actions

export const selectModalOpened = (state: RootState) => state.modal.opened
export const selectModalStatus = (state: RootState) => state.modal.status
export const selectModalSubStatus = (state: RootState) => state.modal.subStatus
export const selectModalPrevStatus = (state: RootState) => state.modal.prevStatus
export const selectModalBackground = (state: RootState) => state.modal.background
export const selectModalShowHeader = (state: RootState) => state.modal.showHeader

export default modalSlice.reducer
