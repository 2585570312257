import styled from 'styled-components'
import { device } from '../../../../constants'

export const NewStepWrapper = styled.div<{ background?: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    & > * {
        margin: 0 20px;
    }

    img.logo {
        margin: 20px 0;
    }

    h2 {
        margin-bottom: 12px;
        color: var(--Sys-Labels-Primary, #000);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 121.429% */
        letter-spacing: -0.8px;
    }

    h4 {
        margin-bottom: 8px;
        color: var(--Sys-Labels-Primary, #000);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.3px;
    }

    p {
        color: var(--Sys-Labels-Primary, #000);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.2px;
    }

    & > button {
        margin-bottom: 40px;
    }

    ul {
        color: var(--Sys-Labels-Primary, #000);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.2px;

        li {
            margin-bottom: 10px;
        }
    }

    .image-preview {
        background: #f2f2f7;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0 15px;
    }

    .image-preview iframe {
        border: 0;
    }
`

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    margin: 10px 40px;

    &:first-of-type {
        margin-top: 16px;
    }
`

export const FooterBlock = styled.div<{ background?: string }>`
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.background ?? 'var(--Sys-Grays-White, #FFFFFF)'};
    //width: calc(100% - 40px);
    width: calc(100% - 40px);
    padding: 16px 20px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @media ${device.tablet} {
        position: relative;
    }
`

export const Notification = styled.div`
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;

    color: var(--Sys-Labels-Secondary, rgba(60, 60, 67, 0.6));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

    a {
        color: var(--Sys-Colors-Accent, #0a84ff);
        text-decoration: none;
    }
`

export const QRCodeWrapper = styled.div`
    display: flex;
    margin: 16px 0;
    padding: 24px;
    border-radius: 8px;
    background: var(--Sys-Grays-White, #fff);
`

export const LoadingOverlay = styled.div`
    margin: 0;
    position: fixed;
    z-index: 1000;
    inset: 0;
    background: var(--Sys-Overlay-Web-Progress, rgba(255, 255, 255, 0.95));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
`

export const SampleBlock = styled.div`
    width: 100%;
    background: var(--Sys-Grays-White, #fff);
    padding: 28px 32px;
    display: flex;
    justify-content: center;

    margin-bottom: 16px;

    img {
        width: calc(100% - 64px);
    }
`

export const FieldBlock = styled.div`
    width: calc(100% - 40px);
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    align-items: flex-start;

    border-radius: 10px;
    background: var(--Sys-BG-Grouped-Secondary-Base, #fff);

    label {
        width: 100%;
        overflow: hidden;
        color: var(--Sys-Labels-Secondary, rgba(60, 60, 67, 0.6));
        font-feature-settings: 'clig' off, 'liga' off;
        text-overflow: ellipsis;

        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
    }

    input {
        width: 100%;
        border: 0;
        outline: 0;
        padding: 0;
        background-color: transparent;
        color: var(--Sys-Labels-Primary, #000);
        text-overflow: ellipsis;

        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.43px;

        &::placeholder {
            color: var(--Sys-Labels-Tertiary, rgba(60, 60, 67, 0.3));
        }
    }
`

export const FieldBlockExt = styled.div`
    width: calc(100% - 16px);
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    align-items: flex-start;

    background: var(--Sys-BG-Grouped-Secondary-Base, #fff);

    &:first-of-type {
        border-radius: var(--Horizontal-Gap, 10px) var(--Horizontal-Gap, 10px) 0 0;
    }
    &:last-of-type {
        border-radius: 0 0 var(--Horizontal-Gap, 10px) var(--Horizontal-Gap, 10px);
    }

    &:not(:last-of-type) {
        border-bottom: 0.33px solid var(--Sys-Separators-Non-opaque, rgba(60, 60, 67, 0.29));
    }

    & > label {
        width: 100%;
        overflow: hidden;
        color: var(--Sys-Labels-Secondary, rgba(60, 60, 67, 0.6));
        font-feature-settings: 'clig' off, 'liga' off;
        text-overflow: ellipsis;

        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
    }

    & > input {
        width: 100%;
        border: 0;
        outline: 0;
        padding: 0;
        background-color: transparent;
        color: var(--Sys-Labels-Primary, #000);
        text-overflow: ellipsis;

        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.43px;

        &::placeholder {
            color: var(--Sys-Labels-Tertiary, rgba(60, 60, 67, 0.3));
        }
    }

    &.slide-toggle {
        flex-direction: row;
        align-items: center;

        & > label {
            flex: 1;
            color: var(--Sys-Labels-Primary, #000);
        }

        .custom-checkbox {
            margin-right: 16px;
            width: 52px;
            height: 32px;
            input.status-input {
                display: none;

                + label {
                    height: 100%;
                    width: 100%;
                    > .status-switch {
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        position: relative;
                        background-color: rgba(120, 120, 128, 0.16);
                        color: white;
                        transition: all 0.5s ease;

                        &:before,
                        &:after {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            justify-content: center;
                            transition: all 0.3s ease;
                        }

                        &:before {
                            border-radius: 8px;
                            height: 16px;
                            width: 16px;
                            background-color: white;
                            color: black;
                            left: 8px;
                            top: 8px;
                            z-index: 10;
                            content: '';
                        }

                        &:after {
                            right: 0;
                            content: '';
                        }
                    }
                }

                &:checked + label > .status-switch {
                    background-color: #0a84ff;
                    border-radius: 16px;

                    &:after {
                        left: 0;
                        content: '';
                    }

                    &:before {
                        width: 24px;
                        height: 24px;
                        color: #40c253;
                        border-radius: 50%;
                        left: 24px;
                        top: 4px;
                        content: '';
                    }
                }
            }
        }
    }
`

export const DataBlock = styled.div`
    margin-top: 32px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    align-items: flex-start;

    border-radius: 10px;
    background: var(--Sys-BG-Grouped-Secondary-Base, #fff);

    & > div {
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 100%;
    }

    .image {
        margin: 8px 0;
        display: flex;
        width: 76px;
        height: 48px;
        justify-content: center;
        align-items: center;

        border-radius: 3px;
        border: 0.5px solid var(--Sys-Elements-Card-Photo-Stroke, rgba(255, 255, 255, 0.12));
        background: var(--Sys-Fills-Primary, rgba(120, 120, 128, 0.36));

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .data {
        padding: 11px 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        color: var(--Sys-Labels-Primary, #000);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.27px;

        label {
            color: var(--Sys-Labels-Secondary, rgba(60, 60, 67, 0.6));
        }
    }

    .divider {
        border-bottom: 0.33px solid var(--Sys-Separators-Non-opaque, rgba(60, 60, 67, 0.29));
    }
`

export const DesktopDataBlock = styled.div`
    padding: 10px 20px;
`
