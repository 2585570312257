import { clearImage } from '../utils/image'
import { getAlbaniaGateway } from '../constants/gateways'

export const sendData = async (idNumber: string, layout: string, photo: string) => {
    return fetch(`${getAlbaniaGateway()}/api/v1/albania/access`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            nationalIdNumber: idNumber,
            designType: layout,
            livenessResult: {
                status: 'SUCCESS'
            },
            selfie: clearImage(photo)
        })
    }).then((response) => response.json())
}
