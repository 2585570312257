import { DesktopDataBlock, NewStepWrapper, QRCodeWrapper } from '../NewStep/styles'
import QRCode from 'react-qr-code'
import { useMemo } from 'react'
import { Flex1 } from '../../../Modal/styles'

const SwitchToMobile = () => {
  const url = useMemo(
    () => `${document.location.href}${document.location.href.indexOf('?') > -1 ? '&' : '?'}started`,
    []
  )

  return (
    <NewStepWrapper>
      <h2>Switch to Phone</h2>
      <p>Scan the QR code with your phone camera app</p>

      <QRCodeWrapper>
        <QRCode value={url} width={150} />
      </QRCodeWrapper>

      <DesktopDataBlock>
        <h4>How to scan a QR code?</h4>

        <ul>
          <li>Point your phone’s camera at the QR code.</li>
          <li>If it doesn't work, download a QR code scanner from Google Play or the App Store.</li>
        </ul>
      </DesktopDataBlock>
      <Flex1 />
    </NewStepWrapper>
  )
}

export default SwitchToMobile
