import styled from 'styled-components'
import { device } from '../../constants'

export const ModalWrapper = styled.div`
    position: fixed;
    inset: 0;
    z-index: 1000;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
`

export const ModalContainer = styled.div<{ background?: string }>`
    width: 100vw;
    height: 100%;
    max-width: 492px;
    background-color: ${(props) => props.background ?? 'var(--Sys-Grays-White, #FFFFFF)'};
    display: flex;
    flex-direction: column;
    border-radius: 0;

    @media ${device.tablet} {
        padding: 0 56px;
        width: 100%;
        height: 690px;
        border-radius: var(--Page-View-Corner-View, 24px);
    }
`

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 8px;
    gap: 8px;

    @media ${device.tablet} {
        margin: 0 -56px;
    }

    & > img {
        width: 40px;
        height: 40px;
        margin-left: 12px;
        background-color: #ffffff;
        border-radius: 50%;
    }
`

export const CloseButton = styled.button`
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
`

export const ModalBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const Flex1 = styled.div`
    flex: 1;
`
