import styled from 'styled-components'
import { device } from '../../constants'

export const HomeWrapper = styled.div`
    width: 100vw;
    height: auto;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const HomeBody = styled.div`
    max-width: 768px;
    width: calc(100% - 40px);
    margin: 0 auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 128px;

    &:before {
        content: '';
        display: inline-block;
        width: 336px;
        height: 336px;
        background-image: url('./img/background-pattern.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        position: absolute;
        top: -40px; //adjust your positioning as necessary
        z-index: -1;

        @media ${device.tablet} {
            width: 768px;
            height: 768px;
        }
    }

    & > img {
        margin-top: 64px;
        width: 64px;
        height: 64px;
        background-color: #ffffff;
        border-radius: 50%;

        @media ${device.tablet} {
            width: 80px;
            height: 80px;
        }
    }

    & > h1 {
        margin: 16px 0;
        color: var(--Sys-Labels-Primary, #000);
        text-align: center;

        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px; /* 122.222% */
        letter-spacing: -0.72px;

        @media ${device.tablet} {
            margin: 24px 0;

            font-size: 60px;
            font-style: normal;
            line-height: 72px; /* 120% */
            letter-spacing: -1.2px;
        }
    }

    & > p {
        margin: 16px 13px;
        color: var(--Sys-Labels-Primary, #000);
        text-align: center;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */

        @media ${device.tablet} {
            font-size: 20px;
            line-height: 30px; /* 150% */
        }
    }

    & > button {
        margin-top: 8px;

        @media ${device.tablet} {
            margin-top: 8px;
        }
    }
    & > button:first-of-type {
        margin-top: 32px;

        @media ${device.tablet} {
            margin-top: 48px;
        }
    }
`
