import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 500px;
  margin-top: 100px;
  margin-left: 100px;
  
  .square {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    inset: 0;
    z-index: -1000;
    
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
  }

  canvas {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  .overlay {
    position: absolute;
    inset: 0;
    z-index: 200;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  
  img {
    position: absolute;
    inset: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 300;
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
  }
`
