import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
    width: 100%;

    .square {
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1;
        overflow: hidden;
    }

    video {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 110;

        //transform: rotateY(180deg) translateX(50%) translateY(-50%);
        //-webkit-transform: rotateY(180deg) translateX(50%) translateY(-50%); /* Safari and Chrome */
        //-moz-transform: rotateY(180deg) translateX(50%) translateY(-50%); /* Firefox */

        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg); /* Safari and Chrome */
        -moz-transform: rotateY(180deg); /* Firefox */
    }

    canvas {
        width: 100%;
        aspect-ratio: 1 / 1;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;

        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg); /* Safari and Chrome */
        -moz-transform: rotateY(180deg); /* Firefox */
    }

    .overlay {
        position: absolute;
        inset: 0;
        z-index: 200;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 100%;
            height: 100%;

            transition: width 0.2s ease-in, height 0.2s ease-in;
            -moz-transition: width 0.2s ease-in, height 0.2s ease-in;
            -web-kit-transition: width 0.2s ease-in, height 0.2s ease-in;
        }

        &.found {
            svg {
                width: 90%;
                height: 90%;
            }
        }
    }

    img {
        position: absolute;
        inset: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: 300;
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg); /* Safari and Chrome */
        -moz-transform: rotateY(180deg); /* Firefox */
    }
`
