import { HomeBody, HomeWrapper } from './styles'
import Modal from '../../components/Modal/Modal'
import { useAppDispatch } from '../../redux/hooks'
import { openModal, setModalStatus } from '../../redux/slices/modalSlice'
import { useCallback, useEffect } from 'react'
import Flow from '../../components/Flow/Flow'
import { Button } from '../../components'
import { useSearchParams } from 'react-router-dom'
import { FooterBlock } from '../../components/Flow/steps/NewStep/styles'
import { FlowStatus } from '../../constants/flow'
import { requestOfflineUrl, requestOnlineUrl } from '../../components/Flow/steps/Request/Request'
import { RequestLink } from '../../components/Button/RequestLink'

const Home = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const startHandler = useCallback(() => {
    dispatch(openModal({ reset: true }))
  }, [dispatch])

  useEffect(() => {
    if (searchParams.has('started')) {
      startHandler()
    }
  }, [searchParams, startHandler])

  const openRequest = (subStatus: string) => {
    dispatch(openModal({ reset: true }))
    dispatch(setModalStatus({ status: FlowStatus.REQUEST, background: '#f2f2f7', subStatus }))
  }

  const openOnlineRequest = () => {
    openRequest('online')
  }

  const openOfflineRequest = () => {
    openRequest('offline')
  }

  return (
    <>
      <HomeWrapper>
        <HomeBody>
          <img src='./img/logo_Albania.png' alt='Logo' />
          <h1>Identiteti Kombëtar Dixhital Shqipta</h1>
          <p>
            Për të marrë ID-në tuaj Dixhitale Shqiptare në portofolin tuaj Folio, do të duhet të kryeni një verifikim të
            shpejtë të identitetit.
          </p>
          <p>
            In order to obtain your Albanian Digital ID in your Folio wallet you will need to perform a quick identity
            verification.
          </p>
          <FooterBlock>
            <Button onClick={startHandler}>Start Demo</Button>
            <RequestLink onClick={openOnlineRequest} link={requestOnlineUrl}>
              Request Online (w/o QR)
            </RequestLink>
            <RequestLink onClick={openOfflineRequest} link={requestOfflineUrl}>
              Request Offline (w QR)
            </RequestLink>
          </FooterBlock>
        </HomeBody>
      </HomeWrapper>
      <Modal>
        <Flow />
      </Modal>
    </>
  )
}

export default Home
