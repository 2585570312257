import { configureStore } from '@reduxjs/toolkit'

import modalReducer from './slices/modalSlice'
import dataSlice from './slices/dataSlice'
import configSlice from './slices/configSlice'

const store = configureStore({
    reducer: {
        modal: modalReducer,
        data: dataSlice,
        config: configSlice
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
