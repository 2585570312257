import React, { useEffect } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { HOME_URL, REQUEST_URL, TEST_URL } from './constants'
import Home from './pages/Home/Home'
import Request from './pages/Request/Request'
import Test from './pages/Test/Test'
import { useAppDispatch } from './redux/hooks'
import { setDebug } from './redux/slices/configSlice'

const App: React.FC = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (searchParams.has('debug')) {
      dispatch(setDebug({ debug: true }))
    }
  }, [searchParams])
  return (
    <Routes>
      <Route path={REQUEST_URL} element={<Request />} />
      <Route path={TEST_URL} element={<Test />} />
      <Route path={HOME_URL} element={<Home />} />
    </Routes>
  )
}

/* function App() {
  const [photo, setPhoto] = useState<string | null>( null);
  const onSuccess = (result: FCMPayload) => {
    setPhoto(result.img)
    console.log('success', !!result.img);
  }
  const onError = (err: ERROR_CODE) => {
    console.log('error', err);
  }
  return (
    <div className="App">
      <div>
        {!photo && (<FaceCapture
            onSuccess={onSuccess}
            onError={onError}
            secure={false}
            showOverlay={false}
            showInitialGuidance={false}
            showGetHelpButton={false}

        />)}
        {photo && (
            <img src={`data:image/jpeg;base64,${photo}`} alt="photo" style={{transform: 'scaleX(-1)'}}/>
        )}
      </div>
    </div>
  );
} */

export default App
