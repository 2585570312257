export enum FlowStatus {
    NEW = 'NEW',
    SWITCH_TO_MOBILE = 'SWITCH_TO_MOBILE',
    NUMBER_FORM = 'NUMBER_FORM',
    ADDITIONAL_VERIFICATION = 'ADDITIONAL_VERIFICATION',
    TAKE_PHOTO = 'TAKE_PHOTO',
    CONFIRM_PHOTO = 'CONFIRM_PHOTO',
    SEND_DATA = 'SEND_DATA',
    ADD_TO_WALLET = 'ADD_TO_WALLET',
    ERROR = 'ERROR',
    REQUEST = 'REQUEST'
}
