import styled from 'styled-components';

export const ConfirmPhotoWrapper = styled.div`
  width: 66%;
  aspect-ratio: 1 / 1;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  & > img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
    //transform: rotateY(180deg);
    //-webkit-transform:rotateY(180deg); /* Safari and Chrome */
    //-moz-transform:rotateY(180deg); /* Firefox */
  }
`
