import React, { ReactNode } from 'react'
import { GrayButton, GrayButtonLink } from './styles'
import { isMobile } from 'react-device-detect'

interface Props {
  onClick: () => void
  link: string
  children?: ReactNode
}

export function RequestLink({ onClick, link, children }: Props) {
  if (isMobile) {
    return <GrayButtonLink href={link}>{children}</GrayButtonLink>
  }

  return <GrayButton onClick={onClick}>{children}</GrayButton>
}
