import { Wrapper } from './styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { detectSingleFace, matchDimensions, draw, resizeResults } from 'face-api.js';
import {
    changeFaceDetector,
    getFaceDetectorOptions,
    isFaceDetectionModelLoaded,
    TINY_FACE_DETECTOR,
    updateTimeStats
} from './utils';

const Test = () => {
    const [found, setFound] = useState(false)
    const [take, setTake] = useState(false)
    const [image, setImage] = useState('')
    const [count, setCount] = useState(0)
    const [play, setPlay] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null);
    const overlayRef = useRef<HTMLCanvasElement>(null);


    useEffect(() => {
        run().then(() => {
            console.log('ok');
        })
    }, [])

    useEffect(() => {
        setFound(count > 3)
        setTake(count > 8)
    }, [count])

    useEffect(() => {
        if (take) {
            takePicture()
        }
    }, [take])

    useEffect(() => {
        let interval: any;
        if (play) {
            interval = setInterval(() => onPlay(count), 200)
        }
        return () => clearInterval(interval);
    }, [play, count])

    const onPlay = useCallback(async (c: number): Promise<any> => {
        const videoEl = videoRef.current;
        if (!videoEl) return;

        /*  if(videoEl.paused || videoEl.ended || !isFaceDetectionModelLoaded())
              return setTimeout(() => onPlay(), 200)*/


        const options = getFaceDetectorOptions()

        const ts = Date.now()

        const result = await detectSingleFace(videoEl, options)
        //console.log(count, result);
        //updateTimeStats(Date.now() - ts)
        if (result) {
            setCount(c + 1)
        } else {
            setCount(0)
        }


        if (result) {
            if (overlayRef) {
                const canvas = overlayRef.current
                if (canvas) {
                    //const dims = matchDimensions(canvas, videoEl, true)
                    //console.log(dims, resizeResults(result, dims).box);
                    //console.log(resizeResults(result, dims));
                    //draw.drawDetections(canvas, resizeResults(result, dims))
                }
            }
        }

        //   setTimeout(() => onPlay(), 200)
    }, [count])

    const onLoaded = () => {
        setPlay(true)
    }

    const takePicture = () => {
        const canvas = overlayRef.current;
        const context = canvas?.getContext("2d");
        const video = videoRef.current
        if (context && video && canvas) {
            const size = Math.min(video.videoHeight, video.videoWidth);
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            const data = canvas.toDataURL("image/png");
            setImage(data);
            //photo.setAttribute("src", data);
        }
    }

    const run = async () => {
        // load face detection model
        await changeFaceDetector(TINY_FACE_DETECTOR)
        console.log('run');
        // try to access users webcam and stream the images
        // to the video element
        const stream = await navigator.mediaDevices.getUserMedia({ video: {} })
        console.log(stream);
        const videoEl = videoRef.current
        if (videoEl) {
            videoEl.srcObject = stream
        }
    }

    return (
        <div>
            <Wrapper>
                <div className="square">
                    <video onLoadedMetadata={onLoaded} ref={videoRef} autoPlay muted playsInline></video>
                    <div className="overlay" style={{backgroundImage: `url("./img/${found ? 'blue' : 'white'}-corners.svg")`}} />
                    {image && (<img src={image} />)}
                </div>

                <canvas ref={overlayRef}/>
            </Wrapper>
            <p>{count}</p>
            <p>Found:::: {found ? 'true': 'false'}</p>
            <p>Take: {take ? 'true': 'false'}</p>

        </div>

    )
}

export default Test;
