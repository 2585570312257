import { nets, TinyFaceDetectorOptions } from 'face-api.js'

export const TINY_FACE_DETECTOR = 'tiny_face_detector'

const getCurrentFaceDetectionNet = () => {
    return nets.tinyFaceDetector
}

export const isFaceDetectionModelLoaded = () => {
    return !!getCurrentFaceDetectionNet().params
}

const inputSize = 128
const scoreThreshold = 0.4

export const getFaceDetectorOptions = () => {
    return new TinyFaceDetectorOptions({ inputSize, scoreThreshold })
}

let forwardTimes: number[] = []

export const updateTimeStats = (timeInMs: number) => {
    forwardTimes = [timeInMs].concat(forwardTimes).slice(0, 30)
    const avgTimeInMs = forwardTimes.reduce((total, t) => total + t) / forwardTimes.length
}

export const changeFaceDetector = async (detector: string) => {
    if (!isFaceDetectionModelLoaded()) {
        await getCurrentFaceDetectionNet().load('/')
    }
}

export const run = async (video: HTMLVideoElement) => {
    await changeFaceDetector(TINY_FACE_DETECTOR)
    const videoSettings: MediaTrackConstraints = {
        facingMode: 'user'
    }
    const constraints: MediaStreamConstraints = {
        video: videoSettings,
        audio: false
    }
    const runStream = await navigator.mediaDevices.getUserMedia(constraints)
    video.srcObject = runStream
}

export const options = getFaceDetectorOptions()
