import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface DataState {
    idNumber: string
    layout: string
    photo: string | null
    result?: { documentLink: string; token: string }
    errorCode?: string
}

// Define the initial state using that type
const initialState: DataState = {
    idNumber: '',
    layout: 'FULL',
    photo: null
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setIdNumber: (state, action: PayloadAction<{ id: string }>) => {
            const { id } = action.payload
            state.idNumber = id
        },
        setLayout: (state, action: PayloadAction<{ layout: string }>) => {
            const { layout } = action.payload
            state.layout = layout
        },
        setPhoto: (state, action: PayloadAction<{ photo: string }>) => {
            const { photo } = action.payload
            state.photo = photo
        },
        setResult: (state, action: PayloadAction<{ result: any }>) => {
            const { result } = action.payload
            state.result = result
        },
        setErrorCode: (state, action: PayloadAction<{ errorCode?: string }>) => {
            const { errorCode } = action.payload
            state.errorCode = errorCode
        },
        resetData: (state) => {
            state.idNumber = ''
            state.photo = null
            state.result = undefined
        }
    }
})

// Action creators are generated for each case reducer function
export const { setIdNumber, setLayout, setPhoto, setResult, setErrorCode, resetData } = dataSlice.actions

export const selectIdNumber = (state: RootState) => state.data.idNumber
export const selectLayout = (state: RootState) => state.data.layout
export const selectPhoto = (state: RootState) => state.data.photo
export const selectResult = (state: RootState) => state.data.result
export const selectErrorCode = (state: RootState) => state.data.errorCode

export default dataSlice.reducer
