import React, { useEffect, useMemo, useRef, useState } from 'react'

import { FieldBlock } from './styles'

type Props = {
  label: string
  name: string
  placeholder: string
  initialValue?: string
  onChange: (value: string) => void
}

const FieldText = ({ name, label, placeholder, initialValue, onChange }: Props) => {
  const [value, setValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    onChange(value)
  }, [value])

  useEffect(() => {
    if (initialValue && inputRef.current) {
      inputRef.current.value = initialValue
      setValue(inputRef.current.value)
    }
  }, [initialValue])

  const changeHandler = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value)
  }

  return (
    <FieldBlock>
      <label>{label}</label>
      <input
        ref={inputRef}
        type='text'
        id={name}
        name={name}
        maxLength={11}
        onChange={changeHandler}
        placeholder={placeholder}
      />
    </FieldBlock>
  )
}

export default FieldText
