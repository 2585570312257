import QRCode from 'react-qr-code'
import { HomeBody, HomeWrapper } from '../Home/styles'
import Modal from '../../components/Modal/Modal'
import { requestOnlineUrl } from '../../components/Flow/steps/Request/Request'

const Home = () => {
  return (
    <>
      <HomeWrapper>
        <HomeBody>
          <img src="./img/logo_Albania.png" alt="Logo" />
          <h1>Request</h1>
          <p>Scan qr code to get request</p>
          <QRCode value={requestOnlineUrl} width={150} />
        </HomeBody>
      </HomeWrapper>
      <Modal>4444</Modal>
    </>
  )
}

export default Home
