import { FieldBlock, FieldBlockExt, FooterBlock, NewStepWrapper, SampleBlock } from '../NewStep/styles'
import { Flex1 } from '../../../Modal/styles'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button } from '../../../Button/styles'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { selectModalBackground, setModalStatus } from '../../../../redux/slices/modalSlice'
import { FlowStatus } from '../../../../constants/flow'
import { selectErrorCode, selectIdNumber, setIdNumber, setLayout } from '../../../../redux/slices/dataSlice'
import { ErrorText } from './styles'
import FieldSlideToggle from './fields/FieldSlideToggle'
import FieldText from './fields/FieldText'

const NumberForm = () => {
  const [id, setId] = useState('')
  const [simple, setSimple] = useState(false)
  const dispatch = useAppDispatch()
  const enabled = useMemo(() => id.length === 11, [id])
  const inputRef = useRef<HTMLInputElement>(null)
  const idNumber = useAppSelector(selectIdNumber)
  const background = useAppSelector(selectModalBackground)
  const errorCode = useAppSelector(selectErrorCode)

  useEffect(() => {
    if (idNumber && inputRef.current) {
      inputRef.current.value = idNumber
    }
  }, [idNumber, inputRef.current])

  const changeHandler = (value: string) => {
    setId(value)
  }

  const changeLayoutHandler = (value: boolean) => {
    setSimple(value)
  }

  const continueHandler = () => {
    dispatch(setIdNumber({ id }))
    dispatch(setLayout({ layout: simple ? 'SIMPLE' : 'FULL' }))
    dispatch(setModalStatus({ status: FlowStatus.ADDITIONAL_VERIFICATION }))
  }

  return (
    <NewStepWrapper>
      <SampleBlock>
        <img src='./img/albaniaexample.png' alt='id' />
      </SampleBlock>
      <h2>Provide Personal Identification Number</h2>
      <FieldText
        label='Personal Identification Number'
        name='idNumber'
        placeholder='Enter PIN'
        onChange={changeHandler}
      />
      <FieldSlideToggle label='Issue a card with simple layout' name='layout' onChange={changeLayoutHandler} />
      {errorCode && <ErrorText>Invalid number</ErrorText>}

      <Flex1 />

      <FooterBlock background={background}>
        <Button key={new Date().getTime()} onClick={continueHandler} disabled={!enabled}>
          Continue
        </Button>
      </FooterBlock>
    </NewStepWrapper>
  )
}

export default NumberForm
