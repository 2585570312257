import { FooterBlock, NewStepWrapper } from '../NewStep/styles'
import { Flex1 } from '../../../Modal/styles'
import { GrayButton } from '../../../Button/styles'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { setModalStatus } from '../../../../redux/slices/modalSlice'
import { FlowStatus } from '../../../../constants/flow'
import { selectErrorCode } from '../../../../redux/slices/dataSlice'
import { useMemo } from 'react'

enum ERROR_CODES {
  NOT_FOUND = 'error.profile.not_found'
}

const titleMap = {
  [ERROR_CODES.NOT_FOUND]: 'Verification Failed'
}
const subTitleMap: Record<string, string> = {
  [ERROR_CODES.NOT_FOUND]:
    'The National Identification Number and the photo you provided do not match. Please double-check your entries and try again.'
}

const Error = () => {
  const dispatch = useAppDispatch()
  const errorCode = useAppSelector(selectErrorCode) as ERROR_CODES

  const title = useMemo(() => {
    return titleMap[errorCode] ?? 'Identity Verification Error'
  }, [errorCode])

  const subTitle = useMemo(() => {
    return (
      subTitleMap[errorCode] ??
      "We're having trouble verifying your identity. Please ensure your face is clearly visible and try again."
    )
  }, [errorCode])

  const tryAgainHandler = () => {
    if (errorCode === ERROR_CODES.NOT_FOUND) {
      dispatch(setModalStatus({ status: FlowStatus.NUMBER_FORM, background: '#f2f2f7' }))
    } else {
      dispatch(setModalStatus({ status: FlowStatus.TAKE_PHOTO, subStatus: 'started' }))
    }
  }

  return (
    <NewStepWrapper>
      <img src='./img/triangle-exclamation.svg' className='logo' alt='logo' />
      <h2>{title}</h2>
      <p>{subTitle}</p>
      <Flex1 />
      <FooterBlock>
        <GrayButton key={new Date().getTime()} onClick={tryAgainHandler}>
          Try Again
        </GrayButton>
      </FooterBlock>
    </NewStepWrapper>
  )
}

export default Error
