import styled from 'styled-components'

export const FieldTextWrapper = styled.div``

export const FieldBlock = styled.div`
    width: calc(100% - 16px);
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    align-items: flex-start;

    background: var(--Sys-BG-Grouped-Secondary-Base, #fff);

    &:first-of-type {
        border-radius: var(--Horizontal-Gap, 10px) var(--Horizontal-Gap, 10px) 0 0;
    }
    &:last-of-type {
        border-radius: 0 0 var(--Horizontal-Gap, 10px) var(--Horizontal-Gap, 10px);
    }

    &:not(:last-of-type) {
        border-bottom: 0.33px solid var(--Sys-Separators-Non-opaque, rgba(60, 60, 67, 0.29));
    }

    & > label {
        width: 100%;
        overflow: hidden;
        color: var(--Sys-Labels-Secondary, rgba(60, 60, 67, 0.6));
        font-feature-settings:
            'clig' off,
            'liga' off;
        text-overflow: ellipsis;

        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.23px;
    }

    & > input {
        width: 100%;
        border: 0;
        outline: 0;
        padding: 0;
        background-color: transparent;
        color: var(--Sys-Labels-Primary, #000);
        text-overflow: ellipsis;

        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.43px;

        &::placeholder {
            color: var(--Sys-Labels-Tertiary, rgba(60, 60, 67, 0.3));
        }
    }

    &.slide-toggle {
        flex-direction: row;
        align-items: center;

        & > label {
            flex: 1;
            color: var(--Sys-Labels-Primary, #000);
        }

        .custom-checkbox {
            margin-right: 16px;
            width: 52px;
            height: 32px;
            input.status-input {
                display: none;

                + label {
                    height: 100%;
                    width: 100%;
                    > .status-switch {
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        position: relative;
                        background-color: rgba(120, 120, 128, 0.16);
                        color: white;
                        transition: all 0.5s ease;

                        &:before,
                        &:after {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            justify-content: center;
                            transition: all 0.3s ease;
                        }

                        &:before {
                            border-radius: 8px;
                            height: 16px;
                            width: 16px;
                            background-color: white;
                            color: black;
                            left: 8px;
                            top: 8px;
                            z-index: 10;
                            content: '';
                        }

                        &:after {
                            right: 0;
                            content: '';
                        }
                    }
                }

                &:checked + label > .status-switch {
                    background-color: #0a84ff;
                    border-radius: 16px;

                    &:after {
                        left: 0;
                        content: '';
                    }

                    &:before {
                        width: 24px;
                        height: 24px;
                        color: #40c253;
                        border-radius: 50%;
                        left: 24px;
                        top: 4px;
                        content: '';
                    }
                }
            }
        }
    }
`
