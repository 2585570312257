import { useEffect, useState } from 'react'
import { FieldBlockExt } from '../../NewStep/styles'

type Props = {
  label: string
  name: string
  onChange: (value: boolean) => void
}

const FieldSlideToggle = ({ label, name, onChange }: Props) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    onChange(value)
  }, [value])

  const changeHandler = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.checked)
  }

  return (
    <FieldBlockExt className='slide-toggle'>
      <label>{label}</label>
      <div className='custom-checkbox'>
        <input id={name} className='status-input' type='checkbox' name={name} onChange={changeHandler} />
        <label htmlFor={name}>
          <div className='status-switch' data-unchecked='Off' data-checked='On'></div>
        </label>
      </div>
    </FieldBlockExt>
  )
}

export default FieldSlideToggle
