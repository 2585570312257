import styled from 'styled-components'

export const QRCodeWrapper = styled.div`
    margin-top: 96px;
    width: 150px;
    height: 150px;
    padding: 24px;
    border-radius: 8px;
    background: var(--Sys-Grays-White, #fff);
`
