import styled from 'styled-components'

type ButtonProps = {
    width?: string
}

export const Button = styled.button<ButtonProps>`
    z-index: 900;
    width: ${(props) => props.width ?? '100%'};
    max-width: var(--Button-Button-Max-W, 390px);
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
    border: 0;
    outline: 0;

    background: ${(props) =>
        props.disabled ? 'var(--Sys-Fills-Tertiary, rgba(118, 118, 128, 0.12))' : 'var(--Sys-Colors-Accent, #0A84FF)'};
    color: ${(props) =>
        props.disabled ? 'var(--Sys-Labels-Tertiary, rgba(60, 60, 67, 0.30))' : 'var(--Sys-Grays-White, #FFF)'};
    pointer-events: ${(props) => (props.disabled ? 'none' : null)};

    cursor: pointer;

    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.3px;

    &:hover {
        background: var(--Brand-Blue-700, #0564ff);
    }

    &:active {
        background: var(--Brand-Blue-600-Key, #0a84ff);
        box-shadow: 0 0 0 5px rgba(123, 189, 255, 0.3);
    }
`
export const ButtonLink = styled.a<{ disabled?: boolean }>`
    z-index: 900;
    width: 100%;
    max-width: var(--Button-Button-Max-W, 390px);
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
    border: 0;
    outline: 0;

    background: var(--Sys-Colors-Accent, #0a84ff);
    color: var(--Sys-Grays-White, #fff);

    cursor: pointer;

    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.3px;

    text-decoration: none;

    background: ${(props) =>
        props.disabled ? 'var(--Sys-Fills-Tertiary, rgba(118, 118, 128, 0.12))' : 'var(--Sys-Colors-Accent, #0A84FF)'};
    color: ${(props) =>
        props.disabled ? 'var(--Sys-Labels-Tertiary, rgba(60, 60, 67, 0.30))' : 'var(--Sys-Grays-White, #FFF)'};
    pointer-events: ${(props) => (props.disabled ? 'none' : null)};

    &:hover {
        background: var(--Brand-Blue-700, #0564ff);
    }

    &:active {
        background: var(--Brand-Blue-600-Key, #0a84ff);
        box-shadow: 0 0 0 5px rgba(123, 189, 255, 0.3);
    }
`

export const GrayButton = styled(Button)<ButtonProps>`
    background: var(--Sys-Fills-Tertiary, rgba(118, 118, 128, 0.12));
    color: ${(props) =>
        props.disabled ? 'var(--Sys-Labels-Tertiary, rgba(60, 60, 67, 0.30))' : 'var(--Sys-Colors-Accent, #0A84FF)'};

    &:hover {
        background: var(--Sys-Fills-Secondary, rgba(120, 120, 128, 0.32));
    }

    &:active {
        background: var(--Sys-Fills-Tertiary, rgba(118, 118, 128, 0.24));
        box-shadow: 0 0 0 4px rgba(143, 143, 143, 0.3);
    }
`
export const GrayButtonLink = styled(ButtonLink)`
    background: var(--Sys-Fills-Tertiary, rgba(118, 118, 128, 0.12));
    color: var(--Sys-Colors-Accent, #0a84ff);

    &:hover {
        background: var(--Sys-Fills-Secondary, rgba(120, 120, 128, 0.32));
    }

    &:active {
        background: var(--Sys-Fills-Tertiary, rgba(118, 118, 128, 0.24));
        box-shadow: 0 0 0 4px rgba(143, 143, 143, 0.3);
    }
`
