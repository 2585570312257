import styled from 'styled-components';

export const CaptureWrapper = styled.div`
  position: relative;

  height:0;width:100%;padding-bottom:100%;
  
  video + div {
    aspect-ratio: 1 / 1;
  }
`
export const CaptureWrapperCorners = styled.div`
  position: absolute;
  inset: 0;
  background-image: url('./img/corners.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  z-index: 900;
`

export const CaptureWrapperOverlay = styled.div`
  position: absolute;
  inset: 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 1000;
`

