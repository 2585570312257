import { FooterBlock, LoadingOverlay, NewStepWrapper } from '../NewStep/styles'
import { Flex1 } from '../../../Modal/styles'
import { Button, GrayButton } from '../../../Button/styles'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { selectPhoto } from '../../../../redux/slices/dataSlice'
import { setModalStatus } from '../../../../redux/slices/modalSlice'
import { FlowStatus } from '../../../../constants/flow'
import { useState } from 'react'
import { checkLiveness } from '../../../../services/liveness.service'
import { ConfirmPhotoWrapper } from './styles'
import { Loader } from '../../../Loader/styles'

const ConfirmPhoto = () => {
  const [processing, setProcessing] = useState(false)
  const dispatch = useAppDispatch()
  const photo = useAppSelector(selectPhoto)

  const retakeHandler = () => {
    dispatch(setModalStatus({ status: FlowStatus.TAKE_PHOTO, subStatus: 'started' }))
  }

  const continueHandler = async () => {
    if (photo) {
      setProcessing(true)
      try {
        const result = await checkLiveness(photo)
        setTimeout(() => {
          if (result?.status === 'SUCCESS') {
            dispatch(setModalStatus({ status: FlowStatus.SEND_DATA, background: '#f2f2f7' }))
          } else {
            dispatch(setModalStatus({ status: FlowStatus.ERROR }))
          }
        }, 500)
      } catch (e) {
        dispatch(setModalStatus({ status: FlowStatus.ERROR }))
      }
    }
  }

  return (
    <NewStepWrapper>
      <ConfirmPhotoWrapper>{photo && <img src={photo} alt='photo' />}</ConfirmPhotoWrapper>
      <h2 style={{ marginTop: '32px' }}>Review your Photo</h2>
      <p>Make sure your selfie clearly shows your face.</p>
      <Flex1 />
      <FooterBlock>
        <Button key={new Date().getTime()} onClick={continueHandler}>
          Continue
        </Button>
        <GrayButton key={new Date().getTime() + 1} onClick={retakeHandler}>
          Retake
        </GrayButton>
      </FooterBlock>
      {processing && (
        <LoadingOverlay>
          <Loader />
          Processing image...
        </LoadingOverlay>
      )}
    </NewStepWrapper>
  )
}

export default ConfirmPhoto
