import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { detectSingleFace } from 'face-api.js'
import { options, run } from './utils'
import { Wrapper } from './styles'
import Corners from './Corners'

type Props = {
  started: boolean
  onSuccess: (image: string) => void
  onReady?: () => void
  onFound?: (found: boolean) => void
}

const canvasSize = 1000

const Capture = ({ started, onSuccess, onReady, onFound }: Props) => {
  const [play, setPlay] = useState(false)
  const [found, setFound] = useState(false)
  const [take, setTake] = useState(false)
  const [count, setCount] = useState(0)

  const videoRef = useRef<HTMLVideoElement>(null)
  const overlayRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      run(videoRef.current).then()
    }
  }, [videoRef.current])

  const constraints = useMemo(() => {
    const video = videoRef.current
    if (video?.videoWidth) {
      const w = video.videoWidth
      const h = video.videoHeight
      const aspectRatio = w / h
      if (aspectRatio > 1) {
        const delta = (w - h) / 2
        return {
          x: delta,
          y: 0,
          size: h
        }
      } else {
        const delta = (h - w) / 2
        return {
          x: 0,
          y: delta,
          size: w
        }
      }
    }
  }, [videoRef.current?.videoWidth])

  const drawImage = useCallback(() => {
    if (videoRef.current && overlayRef.current && constraints) {
      const canvas = overlayRef.current
      if (!canvas) return

      const { x, y, size } = constraints
      const ctx = canvas.getContext('2d', { alpha: true })
      ctx?.drawImage(videoRef.current, x, y, size, size, 0, 0, canvasSize, canvasSize)
    }
  }, [videoRef.current, overlayRef.current, constraints])

  useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.width = canvasSize
      overlayRef.current.height = canvasSize
    }
  }, [overlayRef.current])

  useEffect(() => {
    let interval: any
    if (constraints) {
      interval = setInterval(async () => {
        drawImage()
      }, 1000 / 30)
    }
    return () => {
      clearInterval(interval)
    }
  }, [constraints, drawImage])

  const onPlay = useCallback(
    async (c: number): Promise<any> => {
      const canvas = overlayRef.current
      if (!canvas) return

      const result = await detectSingleFace(canvas, options)

      setCount(result ? c + 1 : 0)
    },
    [overlayRef.current]
  )

  useEffect(() => {
    let interval: any
    if (play) {
      interval = setInterval(async () => {
        await onPlay(count)
      }, 200)
    }
    return () => clearInterval(interval)
  }, [play, count, onPlay])
  useEffect(() => {
    if (started) {
      setPlay(true)
    }
  }, [started])

  useEffect(() => {
    const fnd = count > 4
    setFound(fnd)
    onFound && onFound(fnd)
    setTake(count > 10)
  }, [count, onFound])

  useEffect(() => {
    if (take) {
      const canvas = overlayRef.current
      if (canvas) {
        const tempCanvas = document.createElement('canvas')
        const tempContext = tempCanvas.getContext('2d', { alpha: false })
        tempCanvas.width = canvasSize
        tempCanvas.height = canvasSize

        if (tempContext) {
          tempContext.translate(canvasSize, 0)
          tempContext.scale(-1, 1)
          tempContext.drawImage(canvas, 0, 0)
        }

        onSuccess(tempCanvas.toDataURL('image/png'))
      }
    }
  }, [take, onSuccess, overlayRef.current])

  const onLoaded = () => {
    onReady && onReady()
  }

  return (
    <Wrapper>
      <div className='square'>
        <canvas ref={overlayRef} />
        <video onLoadedMetadata={onLoaded} ref={videoRef} autoPlay muted playsInline></video>
        <Corners active={found} />
      </div>
    </Wrapper>
  )
}

export default Capture
