import styled from 'styled-components'

export const Loader = styled.div`
    border: 2px solid var(--Sys-Colors-Accent, #0a84ff);
    border-radius: 50%;
    border-top: 2px solid transparent;
    width: 24px;
    height: 24px;
    webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
