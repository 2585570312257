import { useMemo } from 'react'

type Props = {
  active: boolean
}

const Corners = ({ active }: Props) => {
  const color = useMemo(() => (active ? '#0A84FF' : 'white'), [active])
  return (
    <div className={active ? 'overlay found' : 'overlay'}>
      <svg width='428' height='428' viewBox='0 0 428 428' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M37 56C37 45.5066 45.5066 37 56 37H90V43H56C48.8203 43 43 48.8203 43 56H37ZM90 90H40H90ZM37 90V56C37 45.5066 45.5066 37 56 37V43C48.8203 43 43 48.8203 43 56V90H37ZM90 40V90V40Z'
          fill={color}
          mask='url(#path-1-inside-1_23562_15095)'
        />
        <path
          d='M338 37H372C382.493 37 391 45.5066 391 56H385C385 48.8203 379.18 43 372 43H338V37ZM388 90H338H388ZM338 90V40V90ZM372 37C382.493 37 391 45.5066 391 56V90H385V56C385 48.8203 379.18 43 372 43V37Z'
          fill={color}
          mask='url(#path-3-inside-2_23562_15095)'
        />
        <path
          d='M40 338H90H40ZM90 391H56C45.5066 391 37 382.493 37 372H43C43 379.18 48.8203 385 56 385H90V391ZM56 391C45.5066 391 37 382.493 37 372V338H43V372C43 379.18 48.8203 385 56 385V391ZM90 338V388V338Z'
          fill={color}
          mask='url(#path-5-inside-3_23562_15095)'
        />
        <path
          d='M338 338H388H338ZM391 372C391 382.493 382.493 391 372 391H338V385H372C379.18 385 385 379.18 385 372H391ZM338 388V338V388ZM391 338V372C391 382.493 382.493 391 372 391V385C379.18 385 385 379.18 385 372V338H391Z'
          fill={color}
          mask='url(#path-7-inside-4_23562_15095)'
        />
      </svg>
    </div>
  )
}

export default Corners
