import React, { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../redux/hooks'
import { selectResult } from '../redux/slices/dataSlice'

declare global {
  interface Window {
    branch?: {
      init: (key: string, options: any, callback: (err: any, data: { has_app: boolean }) => void) => void
      link: (linkData: any, callback: (err: any, data: string) => void) => void
    }
  }
}

const isProduction = (url: string) => url.includes('folio.id')

const getKeyByEnv = (url: string): string =>
  isProduction(url) ? 'key_live_aiBFXsJi2AjvegKtfe2XcndaAqot3sn9' : 'key_test_hjtw0ySg3soxokQqio3U0liiEwht9CnT'

const loadBranch = (): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    if (!window || !document) reject('Not a client')
    ;(function (b: Window, r: Document, c, h, _, s) {
      const a = 'script'
      const n = 'branch'
      // @ts-ignore
      if (!b[n] || !b[n]._q) {
        for (; s < _.length; ) c(h, _[s++])
        const d = r.createElement(a)
        d.async = true
        d.src = '/scripts/latest.min.js'
        const k = r.getElementsByTagName(a)[0]
        k.parentNode?.insertBefore(d, k)
        // @ts-ignore
        b[n] = h
        resolve(h)
      } else {
        // @ts-ignore
        resolve(b[n])
      }
    })(
      window,
      document,
      function (b: any, r: any) {
        b[r] = function () {
          b._q.push([r, arguments])
        }
      },
      {
        _q: [],
        _v: 1
      },
      'addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking setAPIResponseCallback'.split(
        ' '
      ),
      0
    )
  })
}

const initBranch = (key: string): Promise<{ inited: boolean; has_app: boolean }> => {
  return new Promise((resolve, reject) => {
    window?.branch?.init(key, { no_journeys: true }, function (err: any, data: { has_app: boolean }) {
      if (err) {
        console.log(err)
        resolve({ inited: false, has_app: false })
      } else {
        resolve({ inited: true, has_app: data.has_app })
      }
    })
  })
}

function getLinkData(link: string) {
  const data = {
    $canonical_url: link,
    $deeplink_path: link
  }

  return {
    channel: 'web',
    data
  }
}

const getBranchLink = (link: string, has_app: boolean): Promise<string | undefined> => {
  return new Promise<string | undefined>((resolve, reject) => {
    const linkData = getLinkData(link)
    console.log(linkData)

    return window?.branch?.link(linkData, function (err: any, data: string) {
      if (err) {
        console.log(err)
        reject(null)
      } else {
        resolve(data)
      }
    })
  })
}

interface Props {
  WrappedComponent: React.ReactNode | any
}

const BranchProvider = ({ WrappedComponent }: Props) => {
  const result = useAppSelector(selectResult)
  const documentLink = useMemo(() => result?.documentLink, [result?.documentLink])
  const [inited, setInited] = useState(false)
  const [branchLink, setBranchLink] = useState<string | null>(null)
  const [isTimeout, setIsTimeout] = useState(false)
  const key = useMemo(() => getKeyByEnv(window.location.href), [])

  useEffect(() => {
    ;(async () => {
      await loadBranch()
      setInited(true)
    })()
  }, [])

  useEffect(() => {
    if (!inited) return
    ;(async () => {
      if (!documentLink) {
        console.error('No document link')
        return
      }

      const { has_app, inited } = await initBranch(key ?? '')
      if (!inited) {
        console.error('Branch.io script not loaded')
        return
      }

      const link = await getBranchLink(documentLink, has_app)

      if (!link) {
        console.error('Branch.io link not found')
        return
      }

      setBranchLink(link)
    })()
  }, [inited, key, documentLink])

  useEffect(() => {
    setTimeout(() => {
      setIsTimeout(true)
    }, 2000)
  }, [])

  return <WrappedComponent disabled={!branchLink && !isTimeout} link={branchLink || documentLink} />
}

export default BranchProvider
