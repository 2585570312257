import { FooterBlock, NewStepWrapper } from '../NewStep/styles'
import { Flex1 } from '../../../Modal/styles'
import { Button } from '../../../Button/styles'
import { setModalStatus } from '../../../../redux/slices/modalSlice'
import { FlowStatus } from '../../../../constants/flow'
import { useAppDispatch } from '../../../../redux/hooks'

const AdditionalVerification = () => {
  const dispatch = useAppDispatch()
  const continueHandler = () => {
    dispatch(setModalStatus({ status: FlowStatus.TAKE_PHOTO }))
  }

  return (
    <NewStepWrapper>
      <img src='./img/face-viewfinder.svg' className='logo' alt='logo' />
      <h2>Additional Verification</h2>
      <p>Your face will be scanned to ensure that it’s you who is adding your document.</p>
      <Flex1 />
      <FooterBlock>
        <Button key={new Date().getTime()} onClick={continueHandler}>
          Continue
        </Button>
      </FooterBlock>
    </NewStepWrapper>
  )
}

export default AdditionalVerification
