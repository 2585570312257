import { FooterBlock, NewStepWrapper } from '../NewStep/styles'
import { Flex1 } from '../../../Modal/styles'
import { Button } from '../../../Button/styles'
import { useEffect, useMemo, useState } from 'react'
import { ERROR_CODE } from '@getyoti/react-face-capture'
import { CaptureWrapper, CaptureWrapperOverlay } from './styles'
import { setPhoto } from '../../../../redux/slices/dataSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { selectModalSubStatus, setModalStatus } from '../../../../redux/slices/modalSlice'
import { FlowStatus } from '../../../../constants/flow'
import Capture from '../../../Capture/Capture'

const TakePhoto = () => {
  const [inited, setInited] = useState(false)
  const [found, setFound] = useState(false)
  const [started, setStarted] = useState(false)
  const dispatch = useAppDispatch()
  const subStatus = useAppSelector(selectModalSubStatus)

  useEffect(() => {
    if (subStatus === 'started') {
      setStarted(true)
    }
  }, [subStatus])

  const title = useMemo(() => {
    if (!started) return 'Position your face within the frame'
    return found ? 'Hold your Phone Steady' : 'Position your Face within the Frame'
  }, [started, found])

  const subTitle = useMemo(() => {
    if (!started) return 'Everything in the frame will be securely captured and evaluated.'
    return found ? '' : 'The frame will turn blue when the face is positioned correctly.'
  }, [started, found])

  const onSuccess = (image: string) => {
    dispatch(setPhoto({ photo: image }))
    dispatch(setModalStatus({ status: FlowStatus.CONFIRM_PHOTO }))
  }
  const onError = (err: ERROR_CODE) => {
    console.log('error', err)
  }
  const onReady = () => {
    setInited(true)
  }

  const onFound = (result: boolean) => {
    setFound(result)
  }

  const startHandler = () => {
    setStarted(true)
  }
  return (
    <NewStepWrapper>
      <CaptureWrapper>
        <div>
          <Capture onReady={onReady} onSuccess={onSuccess} onFound={onFound} started={started} />
          {!started && <CaptureWrapperOverlay />}
        </div>
      </CaptureWrapper>

      <h2 style={{ marginTop: '32px' }}>{title}</h2>
      <p>{subTitle}</p>
      <Flex1 />
      {!started && (
        <FooterBlock>
          <Button key={new Date().getTime()} onClick={startHandler} disabled={!inited}>
            Get Started
          </Button>
        </FooterBlock>
      )}
    </NewStepWrapper>
  )
}

export default TakePhoto
